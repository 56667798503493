<template>
  <div
    class="flex flex-col min-w-0 flex-1 space-y-1"
    :data-cy="`offer-field-${props.offerField.name}`"
  >
    <div v-tooltipable class="text-sm truncate text-gray-400">
      {{ title }}
    </div>
    <div class="text-gray-600 overflow-x-auto scrollbar-thin">
      <div class="whitespace-nowrap">{{ formattedValue }}</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { OfferFieldDetails } from "@/models/offers";
import { computed } from "vue";
import { offerFieldFormatter } from "@/helpers/formatting";

const props = defineProps<{
  offerField: OfferFieldDetails;
}>();

const formattedValue = computed(() => {
  if (props.offerField.value === null) {
    return "-";
  }
  return offerFieldFormatter[props.offerField.type](
    props.offerField.value,
    props.offerField.options
  );
});

const title = computed(() => {
  return props.offerField.label.trim() || "-";
});
</script>
